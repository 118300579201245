<template>
    <div class="v-modal download-excel">
        <div class="modal-content">
            <div class="modal-header download-excel__header">
                <h3 class="block-title">
                    Выберите категорию для скачивания отчёта в виде таблицы Excel.
                </h3>

                <div class="block-options close-question-form-wrap">
                    <button
                        type="button"
                        class="btn-block-option close-question-form close-modal"
                        @click="$modal.hide('DownloadExcelModal')"
                    >
                        ×
                    </button>
                </div>
            </div>

            <div class="modal-body download-excel__body">
                <ValidationObserver
                    tag="form"
                    ref="observerQuestion"
                    class="download-excel__from without_double_block"
                    @submit.prevent="onSubmit"
                >
                    <div class="form-radio">
                        <label class="download-excel__control css-control css-control--radio">
                            <input
                                type="radio"
                                name="main-type"
                                class="css-control-input"
                                value="test"
                                v-model="mainType"
                            >
                            <span class="css-control-indicator"></span>
                            Отчёт по статусам тестирования
                        </label>
                    </div>
                    <div v-show="mainType === 'test'" class="download-excel__box mb-10">
                    <div class="row">
                    <div class="col-12 col-md-3">
                    <div class="form-radio">
                    <label class="download-excel__control css-control">
                    <input
                        type="radio"
                        name="test-type"
                        class="css-control-input"
                        value="user"
                        v-model="testType"
                    >
                    <span class="css-control-indicator"></span>
                    Пользователь
                    </label>
                    </div>
                    </div>

                    <div class="col-12 col-md-9 form-group mb-2">
                    <v-select2
                      :class="['download-excel__select', {'is-disabled': testType !== 'user'}]"
                      aria-selected="true"
                      placeholder="Выберите пользователя"
                      label="full_name"
                      :clearable="false"
                      :options="listUsers"
                      :reduce="title => title.id"
                      :value="testForm.user"
                      @input="onSelected"
                    >
                      <span slot="no-options">Ничего не найдено</span>
                    </v-select2>
                    </div>
                    </div>

                    <div class="row">
                    <div class="col-12 col-md-3">
                    <div class="form-radio">
                    <label class="download-excel__control css-control">
                    <input
                      type="radio"
                      name="test-type"
                      class="css-control-input"
                      value="document"
                      v-model="testType"
                    >
                    <span class="css-control-indicator"></span>
                    Документ
                    </label>
                    </div>
                    </div>
                    <div class="col-12 col-md-9 form-group mb-0">
                    <v-select2
                    :class="['download-excel__select', {'is-disabled': testType !== 'document'}]"
                    aria-selected="true"
                    placeholder="Выберите Документ"
                    label="name"
                    :clearable="false"
                    :options="listDocuments"
                    :reduce="title => title.id"
                    :value="testForm.document"
                    @input="onSelected"
                    >
                    <span slot="no-options">Ничего не найдено</span>
                    </v-select2>
                    </div>
                    </div>
                    </div>

                    <div class="form-radio">
                        <label class="download-excel__control css-control css-control--radio">
                            <input
                                type="radio"
                                name="main-type"
                                class="css-control-input"
                                value="structure"
                                v-model="mainType"
                            >
                            <span class="css-control-indicator"></span>
                            Отчёт по структурам
                        </label>
                    </div>

                    <div class="form-radio">
                        <label class="download-excel__control css-control css-control--radio">
                            <input
                                type="radio"
                                name="main-type"
                                class="css-control-input"
                                value="document"
                                v-model="mainType"
                            >
                            <span class="css-control-indicator"></span>
                            Отчёт по документам
                        </label>
                    </div>

                    <div class="form-radio">
                        <label class="download-excel__control css-control css-control--radio">
                            <input
                                type="radio"
                                name="main-type"
                                class="css-control-input"
                                value="docAppointments"
                                v-model="mainType"
                            >
                            <span class="css-control-indicator"></span>
                            Отчёт по документам с назначениями
                        </label>
                    </div>

                    <div class="form-radio">
                        <label class="download-excel__control css-control css-control--radio">
                            <input
                                type="radio"
                                name="main-type"
                                class="css-control-input"
                                value="docAttach"
                                v-model="mainType"
                            >
                            <span class="css-control-indicator"></span>
                            Отчёт по документам со списком прикрепленных документов
                        </label>
                    </div>

                    <div class="download-excel__footer modal-footer">
                        <button
                            class="btn btn-rounded btn-primary-dark"
                            type="submit"
                            :disabled="mainType === 'test' && !testForm[testType]"
                        >
                            Скачать
                        </button>

                        <button
                            class="btn btn-rounded btn-alt-secondary"
                            type="button"
                            @click="$modal.hide('DownloadExcelModal')"
                        >
                            Отмена
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>

        <loading-spinner v-show="isLoader" class="download-excel__spinner LoadingSpinner--overlay"/>
    </div>
</template>

<script>
    import session from '@/api/session';
    import { mapState } from 'vuex';

    export default {
        name: 'DownloadExcelModal',

        data() {
            return {
                mainType: 'test',
                testType: 'user',
                testForm: {
                    user: null,
                    document: null
                },
                listUsers: [],
                listDocuments: [],
                isLoader: false
            };
        },

        computed: {
            is_editor() {
                return this.current_user.is_editor_rubric_docs || this.current_user.is_editor_document || this.current_user.is_admin;
            },

            ...mapState('default_data', [
                'current_user'
            ])
        },

        methods: {
            onSelected(value) {
                const prevSelected = this.testType === 'document' ? 'user' : 'document';

                this.testForm[prevSelected] = null;
                this.testForm[this.testType] = value;
            },

            async onSubmit() {
                this.isLoader = true;

                try {
                    // Отчёт по статусам тестирования
                    if (this.mainType === 'test') {
                        if (this.is_editor) {
                            let blob = {};

                            // user
                            if (this.testType === 'user') {
                                blob = await session.get(`api/v1/document/dump_documents?user_id=${this.testForm[this.testType]}`, { responseType: 'blob' }).then(res => res.data);
                            // document
                            } else {
                                blob = await session.get(`api/v1/document/${this.testForm[this.testType]}/dump_users/`, { responseType: 'blob' }).then(res => res.data);
                            }

                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');

                            link.href = url;
                            link.download = 'wikiworks_documents_test_status.xlsx';
                            link.click();
                        } else {
                            session.get('api/v1/document/dump_documents/', { responseType: 'blob' })
                                .then(res => res.data)
                                .then((blob) => {
                                    const url = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');

                                    link.href = url;
                                    link.download = 'wikiworks_documents_test_status.xlsx';
                                    link.click();
                                });
                        }
                    }

                    // Отчёт по структурам
                    if (this.mainType === 'structure') {
                        await session.get('api/v1/document/dump_report_by_structure/', { responseType: 'blob' })
                            .then(res => res.data)
                            .then((blob) => {
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');

                                link.href = url;
                                link.download = 'wikiworks_all_structures.xlsx';
                                link.click();
                            });
                    }

                    // Отчёт по документам
                    if (this.mainType === 'document') {
                        await session.get('api/v1/document/dump_report/', { responseType: 'blob' })
                            .then(res => res.data)
                            .then((blob) => {
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');

                                link.href = url;
                                link.download = 'wikiworks_all_documents.xlsx';
                                link.click();
                            });
                    }

                    // Отчёт по документам с назначениями
                    if (this.mainType === 'docAppointments') {
                        await session.get('api/v1/document/dump_assign_report/', { responseType: 'blob' })
                            .then(res => res.data)
                            .then((blob) => {
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');

                                link.href = url;
                                link.download = 'wikiworks_documents_appointments.xlsx';
                                link.click();
                            });
                    }

                    // Отчёт по документам со списком прикреплённых документов
                    if (this.mainType === 'docAttach') {
                        await session.get('api/v1/document/dump_attached_material_report/', { responseType: 'blob' })
                            .then(res => res.data)
                            .then((blob) => {
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');

                                link.href = url;
                                link.download = 'wikiworks_documents_attached.xlsx';
                                link.click();
                            });
                    }

                    this.isLoader = false;
                    this.$modal.hide('DownloadExcelModal');
                } catch (error) {
                    this.isLoader = false;

                    this.$swal({
                        title: 'Ошибка!',
                        text: 'При загрузке произошла ошибка. Давайте попробуем ещё раз.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1400
                    });
                }
            }
        },

        async created() {
            await Promise.all([
                session.get('/api/v1/users/all_is_active/'),
                session.get('/api/v1/document/all_list/')
            ])
            .then(([res1, res2]) => {
                this.listUsers = res1.data;
                this.listDocuments = res2.data;
            })
            .catch((error) => {
                console.error(error);
            });
        }
    };
</script>

<style lang="scss">
    [data-modal="DownloadExcelModal"] {
        .v {
            &--modal-box {
                overflow: visible;
            }
        }
    }

    .download-excel {
        &__box {
            padding: 15px 12px;
            border: 1px solid #e4e7ed;
        }

        &__control {
            white-space: nowrap;
        }

        &__select {
            cursor: pointer;
            .vs {
                &__dropdown-toggle {
                    max-height: unset;
                }

                &__selected {
                    position: relative;
                }
            }

            &.is-disabled {
              opacity: .5;
              pointer-events: none;
            }
        }

        &__footer {
            margin-top: 20px;
            justify-content: center;
        }

        &__spinner {
            z-index: 99999 !important;
        }
    }
</style>
